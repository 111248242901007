/**
 * v0 by Vercel.
 * @see https://v0.dev/t/qTDboDBZV7f
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */

import { useState, useEffect } from "react"
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card"
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "@/components/ui/select"
import { Input } from "@/components/ui/input"
import { Checkbox } from "@/components/ui/checkbox"

export default function Component() {
    const marketplaces = [
        { id: "A1RKKUPIHCS9HS", name: "Spain", currency: "EUR", vatRate: 21 },
        { id: "A1F83G8C2ARO7P", name: "United Kingdom", currency: "GBP", vatRate: 20 },
        { id: "A13V1IB3VIYZZH", name: "France", currency: "EUR", vatRate: 20 },
        { id: "A1805IZSGTT6HS", name: "Netherlands", currency: "EUR", vatRate: 21 },
        { id: "A1PA6795UKMFR9", name: "Germany", currency: "EUR", vatRate: 19 },
        { id: "APJ6JRA9NG5V4", name: "Italy", currency: "EUR", vatRate: 22 },
        { id: "A2NODRKZP88ZB9", name: "Sweden", currency: "SEK", vatRate: 25 },
        { id: "A1C3SOZRARQ6R3", name: "Poland", currency: "PLN", vatRate: 23 },
        { id: "ARBP9OOSHTCHU", name: "Egypt", currency: "EGP", vatRate: 14 },
        { id: "A33AVAJ2PDY3EV", name: "Turkey", currency: "TRY", vatRate: 18 },
        { id: "A2VIGQ35RCS4UG", name: "United Arab Emirates", currency: "AED", vatRate: 5 },
        { id: "A21TJRUUN4KGV", name: "India", currency: "INR", vatRate: 18 },
    ]
    const [selectedMarketplace, setSelectedMarketplace] = useState("A1PA6795UKMFR9")
    const [currency, setCurrency] = useState("EUR")
    const [vatRate, setVatRate] = useState(19)
    const handleMarketplaceChange = (marketplaceId: any) => {
        const marketplace = marketplaces.find((mp) => mp.id === marketplaceId)
        if (marketplace) {
            setSelectedMarketplace(marketplaceId)
            setCurrency(marketplace.currency)
            setVatRate(marketplace.vatRate)
        }
    }
    useEffect(() => {
        const defaultMarketplace = marketplaces.find((mp) => mp.id === "A1PA6795UKMFR9")
        if (defaultMarketplace) {
            setCurrency(defaultMarketplace.currency)
            setVatRate(defaultMarketplace.vatRate)
        }
    }, [])
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <Card className="w-full">
                <CardHeader>
                    <CardTitle>
                        <div className="flex items-center gap-2">
                            <SettingsIcon className="w-6 h-6" />
                            Fulfillment Settings
                        </div>
                    </CardTitle>
                </CardHeader>
                <CardContent>
                    <form className="space-y-4">
                        <div className="flex items-center space-x-2">
                            <GlobeIcon className="w-5 h-5 text-gray-500" />
                            <Select value={selectedMarketplace} onValueChange={handleMarketplaceChange}>
                                <SelectTrigger id="marketplace">
                                    <SelectValue placeholder="Select Marketplace" />
                                </SelectTrigger>
                                <SelectContent>
                                    {marketplaces.map((marketplace) => (
                                        <SelectItem key={marketplace.id} value={marketplace.id}>
                                            {marketplace.name}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                        <div className="flex items-center space-x-2">
                            <CurrencyIcon className="w-5 h-5 text-gray-500" />
                            <Select value={currency} onValueChange={(value) => setCurrency(value)}>
                                <SelectTrigger id="currency">
                                    <SelectValue placeholder="Select Currency" />
                                </SelectTrigger>
                                <SelectContent>
                                    {marketplaces
                                        .filter((mp) => mp.id === selectedMarketplace)
                                        .map((mp) => (
                                            <SelectItem key={mp.currency} value={mp.currency}>
                                                {mp.currency}
                                            </SelectItem>
                                        ))}
                                </SelectContent>
                            </Select>
                            <Input
                                type="number"
                                placeholder="VAT rate"
                                value={vatRate}
                                onChange={(value: any) => setVatRate(value)}
                            />
                            <span>%</span>
                        </div>
                        <div className="flex items-center space-x-2">
                            <Checkbox id="central-europe-programme" />
                            <label htmlFor="central-europe-programme">Use Central Europe programme</label>
                        </div>
                        <div className="flex items-center space-x-2">
                            <RulerIcon className="w-5 h-5 text-gray-500" />
                            <Select>
                                <SelectTrigger id="unit-of-measurement">
                                    <SelectValue placeholder="Select Unit" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="cm">Centimeters (cm)</SelectItem>
                                    <SelectItem value="in">Inches (in)</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                        <div className="flex space-x-2">
                            <Input placeholder="Length" />
                            <span>cm</span>
                            <span>x</span>
                            <Input placeholder="Width" />
                            <span>cm</span>
                            <span>x</span>
                            <Input placeholder="Height" />
                            <span>cm</span>
                        </div>
                        <div className="flex items-center space-x-2">
                            <WeightIcon className="w-5 h-5 text-gray-500" />
                            <Input type="number" placeholder="Unit weight" />
                            <span>kg</span>
                        </div>
                        <div className="flex items-center space-x-2">
                            <PackageIcon className="w-5 h-5 text-gray-500" />
                            <Select>
                                <SelectTrigger id="product-category">
                                    <SelectValue placeholder="Select Category" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="books">Books</SelectItem>
                                    <SelectItem value="electronics">Electronics</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                        <div className="flex items-center space-x-2">
                            <TagIcon className="w-5 h-5 text-gray-500" />
                            <Input type="number" placeholder="Item price" />
                            <span>{currency}</span>
                        </div>
                        <div className="flex items-center space-x-2">
                            <TruckIcon className="w-5 h-5 text-gray-500" />
                            <Input type="number" placeholder="Delivery charge" />
                            <span>{currency}</span>
                        </div>
                        <div className="flex items-center space-x-2">
                            <BoxIcon className="w-5 h-5 text-gray-500" />
                            <Input type="number" placeholder="Fulfillment cost (optional)" />
                            <span>{currency}</span>
                        </div>
                        <div className="flex items-center space-x-2">
                            <StoreIcon className="w-5 h-5 text-gray-500" />
                            <Input type="number" placeholder="Monthly storage cost per unit (optional)" />
                            <span>{currency}</span>
                        </div>
                        <div className="flex items-center space-x-2">
                            <BoxesIcon className="w-5 h-5 text-gray-500" />
                            <Input type="number" placeholder="Average inventory units stored (optional)" />
                        </div>
                        <div className="flex items-center space-x-2">
                            <ShoppingCartIcon className="w-5 h-5 text-gray-500" />
                            <Input type="number" placeholder="Estimated monthly units sold (optional)" />
                        </div>
                    </form>
                </CardContent>
            </Card>
            <Card className="w-full">
                <CardHeader>
                    <CardTitle>
                        <div className="flex items-center gap-2">
                            <AppleIcon className="w-6 h-6" />
                            Amazon Fulfillment
                        </div>
                    </CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="flex items-center space-x-2 mb-4">
                        <Checkbox id="central-europe-programme-output" checked />
                        <label htmlFor="central-europe-programme-output">Use Central Europe programme</label>
                    </div>
                    <div className="space-y-4">
                        <div className="flex justify-between">
                            <span>Item price</span>
                            <span>{currency} 149.99</span>
                        </div>
                        <div className="flex justify-between">
                            <span>Delivery charge</span>
                            <span>{currency} 23.95</span>
                        </div>
                        <div className="space-y-2">
                            <div className="flex justify-between">
                                <span>Amazon fees</span>
                                <span>{currency} 68.49</span>
                            </div>
                            <div className="flex justify-between">
                                <span>Referral Fee</span>
                                <span>{currency} 67.50</span>
                            </div>
                            <div className="flex justify-between">
                                <span>Per-Item Fee</span>
                                <span>{currency} 0.99</span>
                            </div>
                            <div className="flex justify-between">
                                <span>Variable Closing Fee</span>
                                <span>{currency} 0.00</span>
                            </div>
                        </div>
                        <div className="space-y-2">
                            <div className="flex justify-between">
                                <span>Fulfillment cost</span>
                                <span>{currency} 5.97</span>
                            </div>
                        </div>
                        <div className="space-y-2">
                            <div className="flex justify-between">
                                <span>Storage cost</span>
                                <span>{currency} 0.01</span>
                            </div>
                            <div className="flex justify-between">
                                <span>Monthly storage cost per unit</span>
                                <span>{currency} 0.01</span>
                            </div>
                            <div className="flex justify-between">
                                <span>Average inventory units stored</span>
                                <span>1</span>
                            </div>
                            <div className="flex justify-between">
                                <span>Estimated monthly units sold</span>
                                <span>1</span>
                            </div>
                            <div className="flex justify-between">
                                <span>Storage cost per unit sold</span>
                                <span>{currency} 0.01</span>
                            </div>
                        </div>
                        <div className="space-y-2">
                            <div className="flex justify-between">
                                <span>Other costs</span>
                                <span>{currency} 23.95</span>
                            </div>
                            <div className="flex justify-between">
                                <span>Estimated VAT</span>
                                <span>
                                    {currency} {(149.99 + 23.95 + 68.49 + 5.97 + 0.01 + 23.95) * (vatRate / 100)}
                                </span>
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}

function AppleIcon(props: any) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M12 20.94c1.5 0 2.75 1.06 4 1.06 3 0 6-8 6-12.22A4.91 4.91 0 0 0 17 5c-2.22 0-4 1.44-5 2-1-.56-2.78-2-5-2a4.9 4.9 0 0 0-5 4.78C2 14 5 22 8 22c1.25 0 2.5-1.06 4-1.06Z" />
            <path d="M10 2c1 .5 2 2 2 5" />
        </svg>
    )
}


function BoxIcon(props: any) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z" />
            <path d="m3.3 7 8.7 5 8.7-5" />
            <path d="M12 22V12" />
        </svg>
    )
}


function BoxesIcon(props: any) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M2.97 12.92A2 2 0 0 0 2 14.63v3.24a2 2 0 0 0 .97 1.71l3 1.8a2 2 0 0 0 2.06 0L12 19v-5.5l-5-3-4.03 2.42Z" />
            <path d="m7 16.5-4.74-2.85" />
            <path d="m7 16.5 5-3" />
            <path d="M7 16.5v5.17" />
            <path d="M12 13.5V19l3.97 2.38a2 2 0 0 0 2.06 0l3-1.8a2 2 0 0 0 .97-1.71v-3.24a2 2 0 0 0-.97-1.71L17 10.5l-5 3Z" />
            <path d="m17 16.5-5-3" />
            <path d="m17 16.5 4.74-2.85" />
            <path d="M17 16.5v5.17" />
            <path d="M7.97 4.42A2 2 0 0 0 7 6.13v4.37l5 3 5-3V6.13a2 2 0 0 0-.97-1.71l-3-1.8a2 2 0 0 0-2.06 0l-3 1.8Z" />
            <path d="M12 8 7.26 5.15" />
            <path d="m12 8 4.74-2.85" />
            <path d="M12 13.5V8" />
        </svg>
    )
}


function CurrencyIcon(props: any) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle cx="12" cy="12" r="8" />
            <line x1="3" x2="6" y1="3" y2="6" />
            <line x1="21" x2="18" y1="3" y2="6" />
            <line x1="3" x2="6" y1="21" y2="18" />
            <line x1="21" x2="18" y1="21" y2="18" />
        </svg>
    )
}


function GlobeIcon(props: any) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle cx="12" cy="12" r="10" />
            <path d="M12 2a14.5 14.5 0 0 0 0 20 14.5 14.5 0 0 0 0-20" />
            <path d="M2 12h20" />
        </svg>
    )
}


function PackageIcon(props: any) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="m7.5 4.27 9 5.15" />
            <path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z" />
            <path d="m3.3 7 8.7 5 8.7-5" />
            <path d="M12 22V12" />
        </svg>
    )
}


function RulerIcon(props: any) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M21.3 15.3a2.4 2.4 0 0 1 0 3.4l-2.6 2.6a2.4 2.4 0 0 1-3.4 0L2.7 8.7a2.41 2.41 0 0 1 0-3.4l2.6-2.6a2.41 2.41 0 0 1 3.4 0Z" />
            <path d="m14.5 12.5 2-2" />
            <path d="m11.5 9.5 2-2" />
            <path d="m8.5 6.5 2-2" />
            <path d="m17.5 15.5 2-2" />
        </svg>
    )
}


function SettingsIcon(props: any) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z" />
            <circle cx="12" cy="12" r="3" />
        </svg>
    )
}


function ShoppingCartIcon(props: any) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle cx="8" cy="21" r="1" />
            <circle cx="19" cy="21" r="1" />
            <path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12" />
        </svg>
    )
}


function StoreIcon(props: any) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="m2 7 4.41-4.41A2 2 0 0 1 7.83 2h8.34a2 2 0 0 1 1.42.59L22 7" />
            <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8" />
            <path d="M15 22v-4a2 2 0 0 0-2-2h-2a2 2 0 0 0-2 2v4" />
            <path d="M2 7h20" />
            <path d="M22 7v3a2 2 0 0 1-2 2v0a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 16 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 12 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 8 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 4 12v0a2 2 0 0 1-2-2V7" />
        </svg>
    )
}


function TagIcon(props: any) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M12.586 2.586A2 2 0 0 0 11.172 2H4a2 2 0 0 0-2 2v7.172a2 2 0 0 0 .586 1.414l8.704 8.704a2.426 2.426 0 0 0 3.42 0l6.58-6.58a2.426 2.426 0 0 0 0-3.42z" />
            <circle cx="7.5" cy="7.5" r=".5" fill="currentColor" />
        </svg>
    )
}


function TruckIcon(props: any) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M14 18V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v11a1 1 0 0 0 1 1h2" />
            <path d="M15 18H9" />
            <path d="M19 18h2a1 1 0 0 0 1-1v-3.65a1 1 0 0 0-.22-.624l-3.48-4.35A1 1 0 0 0 17.52 8H14" />
            <circle cx="17" cy="18" r="2" />
            <circle cx="7" cy="18" r="2" />
        </svg>
    )
}


function WeightIcon(props: any) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle cx="12" cy="5" r="3" />
            <path d="M6.5 8a2 2 0 0 0-1.905 1.46L2.1 18.5A2 2 0 0 0 4 21h16a2 2 0 0 0 1.925-2.54L19.4 9.5A2 2 0 0 0 17.48 8Z" />
        </svg>
    )
}
